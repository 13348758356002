@import "src/variables";
@import "src/mixins";

.card {
  max-width: 645px;
  width: 100%;

  .content {
    padding: $common-lg-padding;
  }
}

.title {
  @include setUppercaseTitle;
}

.row {
  display: flex;
  align-items: baseline;
  margin-bottom: $common-xs-padding;

  @media (max-width: $mobile-max-screen-width) {
    flex-direction: column;
  }

  .title, .value {
    margin-bottom: $common-xs-padding;
  }

  .title {
    white-space: nowrap;
    min-width: 180px;
    margin-right: $common-md-padding;
  }

  .status {
    text-transform: capitalize;
  }
}

.benefitsSection {
  .title {
    margin-bottom: $common-md-padding;
  }

  .benefits {
    white-space: pre-line;
    line-height: 36px;
    margin: 0;

    ul {
      padding-left: $common-lg-padding;

      li {
        list-style: disc;
      }
    }
  }
}

.buttonRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: $common-lg-padding;
}

.divider {
  margin: 0 0 $common-md-padding;
}

.pendingSection {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .spinnerWrapper {
    height: 100%;
  }

  .descriptionWrapper {
    width: 100%;
    text-align: center;
    padding: $common-lg-padding $common-lg-padding 0;
  }
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

$page-select-width: 200px;

.container {
  display: flex;
  flex-direction: column;

  .banner {
    background: url($event-catalog-background-image) center;
    background-size: cover;

    &, .title {
      color: white;
    }

    .title {
      margin: 0;
      font-size: 40px;
    }

    .bannerContent {
      min-height: 400px;
      display: flex;
      flex-direction: column;
    }
  }

  .titleContainer {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .subTitleBox {
      margin-top: $common-md-padding;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 6px;
      padding: $common-sm-padding;
      font-weight: bold;

      @include themify {
        color: $primary-theme-color;
      }
    }
  }

  .searchInput {
    background-color: white;
    margin-bottom: $common-sm-padding;
  }

  .content {
    padding: $common-lg-padding;

    @media (max-width: $mobile-max-screen-width) {
      padding: $common-sm-padding;
    }
  }

  .noData {
    text-align: center;
  }

  .paginationWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pageSelectWrapper {
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: $mobile-max-screen-width) {
    .paginationWrapper {
      margin-left: $page-select-width;
    }

    .pageSelectWrapper {
      width: $page-select-width;
    }
  }

  .select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .selectLabel {
    font-weight: 500;
    font-size: 18px;
    margin-right: $common-sm-padding;
    white-space: nowrap;
  }
}

@import "src/variables";

.divider {
  width: 100%;
  margin: $common-lg-padding auto;
}

.select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selectLabel {
  font-weight: 500;
  font-size: 18px;
  margin-right: $common-md-padding;
}

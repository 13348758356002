@import "src/variables";
@import "src/themify";

.toggle {
  margin-left: 0;
  text-transform: uppercase;

  @include themify {
    color: $primary-theme-color;
  }
}

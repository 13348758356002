@import "src/variables";

.container {
  margin-top: 0;
  padding-bottom: $common-lg-padding;

  .addressSection {
    padding-top: 0;

    h3 {
      margin-top: 0;
    }
  }
}

.balance {
  display: inline-block;
}

@import "src/variables";
@import "src/mixins";

.buttonsWrapper {
  margin-left: auto;
}

.previewButton {
  margin-left: auto;
  margin-right: 10px;
}

.copyInputDescription {
  margin-top: 0;
}

.createdCampaignInfo {
  .value {
    font-style: italic;
  }

  li:not(:last-child) {
    margin-bottom: $common-xs-padding;
  }
}

.stepper {
  margin-bottom: $common-xl-padding;
}

.title {
  @include themify {
    color: $primary-theme-color;
  }

  font-size: 22px;
  font-weight: 700;
}

.amountInput {
  margin-top: $common-sm-padding;
}

.helperText {
  padding-left: $common-sm-padding;
}

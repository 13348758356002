@import "src/variables";
@import "src/mixins";
@import "src/themify";

$list-item-icon-size: $common-md-padding;

p {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.note {
  font-size: 12px;
}

.options {
  li {
    display: flex;
    line-height: $list-item-icon-size;

    &:not(:last-child) {
      margin-bottom: $common-xs-padding;
    }
  }

  .icon {
    @include setSize($list-item-icon-size);

    margin-right: $common-xs-padding;
  }
}

.importantText {
  font-weight: bold;
  
  @include themify {
    color: $secondary-theme-color;
  }
}

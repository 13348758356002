@import "src/variables";
@import "src/mixins";
@import "src/themify";

$application-sidebar-width: 400px;
$collapsed-application-sidebar-width: 100px;

.fullPageSidebar {
  @include themify {
    background-color: $primary-theme-color;
  }

  width: $application-sidebar-width;
  flex-shrink: 0;
  padding-top: $common-xl-padding;
  position: fixed;
  height: 100%;
  z-index: 2;
}

.collapsed {
  .fullPageSidebar {
    width: $collapsed-application-sidebar-width;

    .linkLabel {
      visibility: hidden;
      width: 0;
      white-space: nowrap;
    }

    .linkIcon {
      margin: $common-xs-padding $common-md-padding;
    }

  }

  .fullPageContent {
    padding-left: $collapsed-application-sidebar-width;
    width: 100%;
  }

  .fullPageFormFooter {
    width: calc(100% - $collapsed-application-sidebar-width);
    left: $collapsed-application-sidebar-width;
  }
}

.fullPageFormFooter {
  z-index: 2;
  width: calc(100% - $application-sidebar-width);
  left: $application-sidebar-width;
}

.fullPageContent {
  padding-left: $application-sidebar-width;
}

.link {
  border: 0;
  border-radius: 50%;
  display: flex;

  .linkLabel {
    @include themify {
      background-color: $primary-theme-color;
    }

    display: flex;
    align-items: center;
    padding: $common-xs-padding $common-xs-padding * 3 $common-xs-padding $common-xs-padding;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  .linkIcon {
    @include setIconSize(50px);

    font-size: 24px;
    font-weight: 700;
    margin: $common-xs-padding $common-sm-padding $common-xs-padding $common-md-padding;

    &, &:hover, &:focus {
      background-color: white;
    }

    &:hover + .linkLabel {
      z-index: 0;
      opacity: 1;
      visibility: visible;
      width: auto;
    }
  }
}

.menuButton {
  position: absolute;
  bottom: $common-sm-padding;
  right: 0;
  z-index: 1;

  svg {
    width: 35px;
    height: 35px;
    color: white;
  }
}

@import "src/variables";
@import "src/mixins";

.pageTitle {
  margin-top: 0;
  margin-bottom: $common-lg-padding;
  font-size: 24px;
  line-height: 1;
}

.alert {
  margin-bottom: $common-lg-padding;
}

.hidden {
  visibility: hidden;
  z-index: -1;
  height: 0;
  width: 0;
  position: absolute;
  bottom: 10000px;
}

.tab {
  text-transform: uppercase;
  font-weight: 700;
  max-width: 320px; // for support long text
}

.activeTab {
  @include themify {
    box-shadow: 0px -2px 0px $primary-theme-color inset;
  }
}

.tabPanel {
  padding-left: 0;
  padding-right: 0;
}

.link {
  @include setLinkStyles;
}

.uppercase {
  text-transform: uppercase;
}

.card {
  @include setCardStyles;
}

.text {
  margin: 0;
}

.bold {
  font-weight: bold;
}

.mediumText {
  font-weight: 500;
}

.subTitle {
  @include themify {
    color: $primary-theme-color;
  }

  font-size: 18px;
  font-weight: 700;
  margin: 0 0 $common-sm-padding;
}

.dangerButtonContained {
  color: white;
  background-color: $red;

  &:hover {
    background-color: darken($red, 25%);
  }
}

.dangerButtonOutlined {
  color: $red;
  border: 1px solid rgba($red, 0.5);

  &:hover {
    border: 1px solid $red;
    background-color: rgba($red, 0.04);
  }
}

.dangerIconButton {
  color: $red;

  &:hover {
    background-color: rgba($red, 0.04);
  }
}

.gridItemDivider {
  width: 100%;
  margin-top: $common-lg-padding;
}

// material styles
.placeholder {
  opacity: 0.42;
}

@import "src/variables";

.title {
  display: flex;
  align-items: center;

  .headerIcon {
    margin-right: $common-xs-padding;
    color: $green;
  }
  .headerIconGamsd {
    margin-right: $common-xs-padding;
    color: $light-green;
  }
  .headerIconCompass {
    margin-right: $common-xs-padding;
    color: $bright-green;
  }
}

.description {
  line-height: 30px;
  margin-top: 0;
  display: inline-flex;
  flex-direction: column;
}

.downloadButton {
  margin-left: $common-sm-padding;
  margin-right: auto;
}

.printActionsWrapper {
  display: flex;
  align-items: flex-start;
}

.poNumberField {
  margin-left: $common-sm-padding;
}

@import "src/variables";

.renewButton {
  line-height: 18px;
}

.addButtonWrapper {
  margin-left: auto;

  @media (max-width: $mobile-max-screen-width) {
    order: -1;
  }
}

@import "src/variables";
@import "src/mixins";

$donation-card-padding: $common-lg-padding;

.cardContent {
  padding: $donation-card-padding;

  h2 {
    margin-top: 0;
  }
}

.spinner {
  flex-grow: 1;
}

.cardHeader {
  padding: $donation-card-padding $donation-card-padding 20px;
}

.helperText {
  margin-left: $common-xl-padding;
}

.sectionContent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: $common-xl-padding;
  padding-bottom: $common-xl-padding;

  @media (max-width: $mobile-max-screen-width) {
    flex-direction: column;
  }

}

.createCampaignButton {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $mobile-max-screen-width) {
    margin-top: 50px;
    width: 100%;
  }

  h3 {
    margin-top: 0
  }

}

.socialButtons {
  @media (max-width: $mobile-max-screen-width) {
    display: flex;
    justify-content: center;

    >div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.footer {
  margin-top: auto;
  margin-bottom: 3em;
}

.actionButtons {
  button:first-child {
    margin-right: 20px;
  }
}

.formFooter {
  display: flex;
  justify-content: flex-end;
}

.backLink {
  display: flex;
  align-items: center;
  font-size: 18px;

  .backIcon {
    @include setIconSize(18px);
  }
}

.boldLabel {
  font-weight: 500;
}

.summaryRow {
  font-weight: 700;
  font-size: 22px;
  padding-top: $common-lg-padding;
}

.termsAndConditionsLink {
  @include themify {
    color: rgba($primary-theme-color, 0.7);
  }
}

.termsAndConditionsLabel {
  font-weight: 700;
  font-size: 18px;
}

@import "src/themify";
@import "src/mixins";
@import "src/variables";

.submitButton {
  margin-left: auto;
}

.label {
  font-weight: 700;
  text-align: right;
}

.value {
  word-wrap: break-word;

  & > span {
    width: 100%;
    display: inline-block;
  }
}

.divider {
  width: 100%;
  @include themify {
    background-color: $primary-theme-color;
  }
}

.title {
  text-transform: uppercase;
  font-size: 18px;
  margin-left: $common-sm-padding;
}

.table {
  box-shadow: none;
}

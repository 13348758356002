@import "src/variables";
@import "src/mixins";
@import "src/themify";

.card {
  border: 2px solid $primary-blue;
  border-radius: 10px;
  height: 200px;
  min-width: 300px;
  width: 100%;
  cursor: pointer;

  &:hover {
    box-shadow: 0 15px 30px rgba(black, 0.25);
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    @include setIconSize(100px);
  }
  .title {
    text-align: center;
    margin: 0;
  }
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

.title {
  display: inline-block;
  margin: $common-xs-padding;
}

.input {
  background-color: white;
  border-radius: 20px;
}

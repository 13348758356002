@import 'src/variables';
@import 'src/mixins';

.returnButton {
  @media (max-width: $mobile-max-screen-width) {
    order: 1;
    margin-top: $common-sm-padding;
  }
}

.footer {
  box-shadow: 0px -10px 30px rgba(black, 0.05);
}

.loginLink {
  @include setLinkStyles(white);

  font-weight: 500;
}

@import 'src/variables';
@import 'src/mixins';

.section {
  margin-top: $common-xs-padding;
}


.socialLinkWrapper {
  display: flex;
  flex-wrap: wrap;

  .socialLink {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: $common-sm-padding;

    .icon {
      @include setIconSize(30px);
      margin-right: $common-sm-padding;
    }

    &:nth-child(2n) {
      margin-left: $common-sm-padding;
      width: calc(50% - $common-sm-padding);
    }
  }

}

@import "src/variables";

.footer {
  padding-top: $common-lg-padding;
  background-color: #003360;
  color: white;
  font-size: 14px;

  .container {
    display: flex;
    justify-content: center;

    @media (max-width: $mobile-max-screen-width) {
      flex-direction: column;
      width: 80%;
    }

    .leftSection, .rightSection {
      width: 30%;
      padding-bottom: $common-lg-padding;

      @media (max-width: $mobile-max-screen-width) {
        width: auto;
      }
    }

    .leftSection {
      .logo {
        margin-bottom: $common-md-padding;
        width: 200px;
        height: 110px;

        @media (max-width: $mobile-max-screen-width) {
          width: 105px;
          height: 57px;
        }
      }
    }

    .middleSection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 4% $common-lg-padding 4%;
      border-left: 1px solid white;
      border-right: 1px solid white;

      @media (max-width: $mobile-max-screen-width) {
        height: 310px;
        padding: 7% 0;
        border-left: none;
        border-right: none;
        border-top: 1px solid white;
        border-bottom: 1px solid white;

        a {
          margin-right: $common-sm-padding;
        }

        .facebookIcon {
          margin-left: -7px;
        }
      }

      .followTitle {
        font-weight: bold;
        font-size: 24px;

        @media (max-width: $mobile-max-screen-width) {
          width: 100%;
        }
      }

      .social {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $mobile-max-screen-width) {
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }

    .rightSection {
      text-transform: uppercase;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-size: 15px;
      padding-left: calc(4% + $common-lg-padding);

      @media (max-width: $mobile-max-screen-width) {
        padding-left: 0;
        padding-top: 7%;
      }

      a {
        color: white;
        margin-bottom: $common-sm-padding;

        &:last-child {
          margin: 0;
        }
      }

      .bookstoreLink {
        padding: $common-sm-padding;
        margin-bottom: $common-sm-padding;
        border: 2px solid white;
      }
    }
  }
}

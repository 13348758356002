@import "src/variables";
@import "src/mixins";
@import "src/themify";

.filterBar {
  padding: $common-md-padding;

  .input {
    background-color: white;
    min-width: 200px;
  }
}


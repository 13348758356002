@import "src/variables";

$review-header-height: 90px;
$content-padding: 20px;

.reviewHeader {
  height: $review-header-height;
  position: fixed;
  padding: $content-padding $content-padding $content-padding 120px;
  right: 0;
  width: 100%;
  z-index: 1;
  background-color: $dark-grey;
  color: white;
  display: flex;

  .reviewInfo {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
  }
}

.row {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: $content-padding;
  }

  .value {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}

.decisionButton {
  border-color: white;
  color: white;
  margin-left: auto;
}

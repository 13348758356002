@import "src/variables";
@import "src/mixins";
@import "src/themify";

.priceCardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $grey;
  text-align: center;
  padding-top: $common-lg-padding;

  .notAvailableEvent {
    font-size: 30px;
    padding: $common-lg-padding;
  }

  .price {
    @include themify {
      color: $primary-theme-color;
    }
    padding-top: $common-sm-padding;
    font-size: 45px;
    font-weight: 700;
    word-wrap: break-word;
  }

  .subtitle {
    text-transform: uppercase;
    font-size: 20px;
  }

  .availableUntilDate {
    font-size: 20px;
  }

  .discount {
    font-weight: bold;
  }

  .member {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;

    .memberLink {
      margin-top: $common-sm-padding;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include themify {
        color: $secondary-theme-color;
      }
    }
  }

  .loginText {
    margin: 0 0 $common-sm-padding;
  }
}

@import "src/variables";

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  .title {
    margin-left: $common-sm-padding;
  }

  .input {
    width: 80px;
    margin-left: auto;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      text-align: right;
    }
  }
}

.divider {
  width: 100%;
  margin-top: $common-md-padding;
  padding-left: $common-md-padding;
}

.helperText {
  font-style: italic;
  color: $grey-disabled;
  margin-left: $common-xs-padding;
  margin-top: $common-xs-padding;
  font-size: 13px;
}

.result {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .value {
    font-weight: bold;
    margin-bottom: $common-xs-padding;
  }
}

.closeButton {
  margin-top: auto;
  height: 100%;
}

.sumError {
  .value {
    color: red;
  }
}

.sumSuccess {
  .value {
    color: green;
  }
}

@import "~normalize.css";
@import "src/mixins";

$default-font-size: 16px;

html, body {
  height: 100%;
}

#root {
  height: 100%;

  .rootContent {
    min-height: 87vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $default-font-size;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  background: white;
}

*, *::before, *::after {
  box-sizing: border-box;
  outline: none;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

// removing google chrome autocomplete inputs styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@import "src/variables";
@import "src/themify";

.submitButton {
  margin-left: auto;
}

.value {
  @include themify {
    color: $primary-theme-color;
  }

  display: inline-flex;
  align-items: center;
}

.infoIcon {
  margin-left: $common-xs-padding;
}

.terms {
  @include themify {
    color: $primary-theme-color;
  }
}

@import "src/variables";
@import "src/mixins";

.card {
  @include setCardStyles;
  z-index: 1;
}

.header {
  flex-wrap: wrap;

  & + .content {
    padding-top: 0;
  }
}

.action {
  margin: 0;
}

.title {
  display: inline-flex;
  align-items: center;

  & > * {
    margin: 0 $common-xs-padding 0 0;
  }
}

.borderedCard {
  @include setCardStyles(true);
}

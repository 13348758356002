@import "src/variables";
@import "src/mixins";

.icon {
  @include setIconSize(20px);
}

.editButton {
  border-color: $navy;
  color: $navy;
}

.addButton {
  margin-bottom: $common-lg-padding;
}

.methodDescription {
  display: flex;
  align-items: center;

  .cardTypeLogo {
    margin-right: calc(#{$common-xs-padding} / 2)
  }
}

@import "src/variables";
@import "src/mixins";

.icon {
  @include setIconSize(20px);
}

.actionButton {
  color: white;
  background-color: $grey;

  &:not(.actionButtonDisabled) {
    &.removeButton {
      background-color: $dark-red;
    }

    &.addButton {
      background-color: $green;
    }
  }

  &.actionButtonDisabled {
    color: $mui-disabled-color;
    background-color: $mui-disabled-bg-color;
  }
}

.searchInputWrapper {
  margin-left: auto;
}

.clearButtonWrapper {
  margin-left: auto;
}

.clearButton {
  text-transform: uppercase;

  &:not(:disabled) {
    color: $blue;
  }
}

.tooltipLink {
  color: white;
}

.tooltipContent {
  max-width: 600px;
}

.tooltip {
  display: flex;
  cursor: pointer;
  align-items: center;

  .icon {
    margin-right: $common-xs-padding;
  }
}

@import 'src/variables';
@import 'src/mixins';

@mixin setLabelColors($color) {
  &.filled {
    background-color: $color;
    color: white;
  }
  &.outlined {
    background-color: white;
    color: $color;
    border-color: $color;
  }
}

.label {
  &.filled {
    color: white;

    span {
      font-weight: bold;
    }
  }

  &.outlined {
    background-color: white;
    border-style: solid;
    border-width: 1px;
  }

  text-align: center;
  padding: calc(#{$common-xs-padding} / 2) $common-xs-padding;
  min-width: 90px;
  min-height: 24px;
  height: auto;
  border-radius: 5px;
  border: 0;

  span {
    padding: 0;
    font-size: 13px;
    line-height: 14px;
    display: inline-block;
    white-space: initial;
  }

  &.primary-theme {
    @include themify {
      @include setLabelColors($primary-theme-color);
    }
  }

  &.green {
    @include themify {
      @include setLabelColors($green-theme-color);
    }
  }

  &.red {
    @include themify {
      @include setLabelColors($red-theme-color);
    }
  }

  &.yellow {
    @include setLabelColors($yellow);
  }

  &.dark-yellow {
    @include setLabelColors($dark-yellow);
  }

  &.blue {
    @include setLabelColors($blue);
  }

  &.navy {
    @include setLabelColors($navy);
  }

  &.violet {
    @include setLabelColors($violet);
  }

  &.grey {
    @include setLabelColors($grey-disabled);
  }

  &.primary-green {
    @include setLabelColors($primary-green);
  }

  &.primary-blue {
    @include setLabelColors($primary-blue);
  }
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

.row {
  display: inline-flex;
  align-items: flex-start;
  line-height: 28px;
  font-size: 18px;
  word-break: break-all;
  margin-left: $common-sm-padding;

  &:not(:last-child) {
    margin-bottom: $common-xs-padding;
  }

  .icon {
    @include setIconSize(27px);

    margin-right: $common-xs-padding;
    color: $grey;
  }

  .location {
    display: inline-block;
    width: 100%;
  }
}

.divider {
  display: none;
  margin: $common-xs-padding 0;
  width: 100%;

  @include themify {
    background-color: $primary-theme-color;
  }
}

.informationWrapper {
  display: flex;
  flex-direction: column;
}

.checkbox {
  height: 37px;
}

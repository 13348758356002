@import "src/variables";
@import "src/themify";

.tile {
  min-height: 175px;
}

.cardButton {
  background-color: white;
  margin-top: auto;

  @include themify {
    color: $primary-theme-color;
  }

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  &:hover {
    background-color: darken($light-grey, 10%);
  }
}

.overdueCard {
  .cardButton {
    color: $dark-red;
  }
}

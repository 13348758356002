@import "src/variables";
@import "src/mixins";

.confirmIcon {
  @include setIconSize(60px);

  color: $green;
}

.confirmContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

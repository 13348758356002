@import "src/variables";
@import "src/mixins";
@import "src/themify";

.container {
  @include themify {
    background: $login-background;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $common-xl-padding 0;
  min-height: 100%;
  height: auto;
}

.logoWrapper {
  background-color: rgba(white, 0.4);

  .mpLogo {
    width: 100%;
    max-width: 600px;
    margin: $common-md-padding auto;
    display: block;
  }
}

.nccerLogo {
  @include setSize(100px);
}

.loginWrapper {
  padding-top: $common-xl-padding;
}

.card {
  background: white;
  box-shadow: 0 1px 41px rgba(41, 7, 120, 0.2);
  padding: $common-xl-padding;

  .cardTitle {
    font-size: 32px;
    line-height: 42px;
  }

  .cardDescription, .helperText {
    line-height: 26px;
  }

  .cardTitle, .cardDescription {
    margin: 0 0 $common-lg-padding;
  }

  .helperText {
    margin: $common-lg-padding 0 0;
  }

  @media (max-width: $mobile-max-screen-width) {
    padding: calc(#{$common-xl-padding} / 2);
  }
}

.signupCard {
  margin-top: $common-xl-padding;
}

.button {
  min-height: 52px;
  text-transform: uppercase;
  font-size: 20px;
  min-width: 190px;
  border-radius: 12px;
}

.highlight {
  @include themify {
    color: $secondary-theme-color;
  }

  font-weight: 700;
}


@import "src/variables";
@import "src/mixins";

.card {
  @include setCardStyles;

  padding: $common-lg-padding;

  .cardContent {
    padding: $common-sm-padding 0 0;
  }
}

.cardHeader {
  @include themify {
    border-bottom: 2px solid $primary-theme-color;
  }

  padding: 0 0 $common-xs-padding;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.cardHeaderAction {
  margin: 0;
  align-self: unset;
}

.cardTitle {
  @include setUppercaseTitle;

  display: inline-flex;
  align-items: center;

  & > * {
    margin: 0 $common-xs-padding 0 0;
  }
}

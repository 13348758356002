@import "src/variables";

.content, .header {
  padding-left: $common-lg-padding;
  padding-right: $common-lg-padding;
}

.header {
  padding-top: $common-lg-padding;
}

.description {
  margin-top: 0;
  color: $grey;
}

.errorsTitle, .questionName {
  font-weight: bold;
  color: $error-red;
}

.errorsList {
  margin-bottom: $common-sm-padding;
  li {
    color: $error-red;
    margin-left: $common-lg-padding;
    margin-bottom: $common-xs-padding;
    list-style: inside;
  }
}

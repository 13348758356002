@import "src/variables";

.badge {
  color: $dark-red;
  background-color: $light-grey;
  box-shadow: 0 4px 4px rgba(black, 0.25);
  font-weight: bold;

  &.noItems {
    color: inherit;
  }
}



@import "src/themify";

.link {
  @include themify {
    color: $primary-theme-color;
  }
  &:hover {
    text-decoration: underline;
  }
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

.title {
  font-size: 17px;
  margin-bottom: $common-md-padding;
  line-height: 0;
}

.divider {
  width: 100%;
  margin-bottom: $common-xs-padding;

  @include themify {
    background-color: $primary-theme-color;
  }
}

.subTitle {
  margin-top: 0;
  font-size: 15px;
}

.section {
  margin-top: $common-xs-padding;
}

.hotelName {
  font-style: italic;
}

.roomTitle {
  font-size: 17px;
  margin: $common-lg-padding 0 $common-sm-padding;
  line-height: 0;
}

.roomHeader {
  .divider {
    width: calc(100% - 40px);
  }

  align-items: center;
  display: flex;
}

.deleteButton {
  margin-left: auto;
}

.disabledText {
  color: $grey;
  font-weight: bold;
  margin: $common-lg-padding 0 $common-sm-padding;
}

.label {
  font-weight: 700;
  line-height: 24px;
}

.value {
  line-height: 24px;
  word-wrap: break-word;
  & > span {
    width: 100%;
    display: inline-block;
  }
}

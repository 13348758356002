@import "src/variables";
@import "src/mixins";
@import "src/themify";

$header-height: 90px;
$default-logo-size: 85px;

.header {
  width: 100%;
  height: $header-height;
  background: white;
  box-shadow: $common-card-box-shadow;
}

.content {
  height: 100%;
  padding-top: $common-md-padding;
  padding-bottom: $common-md-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: block;
  @include setSize($default-logo-size);

  img {
    height: 100%;
  }
}

.actionButton {
  @include themify {
    color: $primary-text-color;
  }
}

.actionIcon {
  @include setIconSize(36px);
}

@import "src/variables";
@import 'src/themify';

@mixin setIconSize($size) {
  font-size: $size;
  height: $size;
  line-height: $size;
  width: $size;
}

@mixin setSize($size) {
  height: $size;
  width: $size;
}

@mixin setCardStyles($withBorder: false) {
  background: white;
  box-shadow: $common-card-box-shadow;
  border-radius: 6px;
  border: 0;

  @if $withBorder {
    border: 1px solid $light-grey;
  }
}

@mixin setLinkStyles($color: $blue) {
  color: $color;
  text-decoration: underline;
  cursor: pointer;
}

@mixin setUppercaseTitle() {
  text-transform: uppercase;
  margin: 0;
  font-weight: 700;

  @include themify {
    color: $primary-theme-color;
  }
}

@mixin setTextEllipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin setPageContentStyles($hasFooter: false, $verticalPadding: $common-md-padding) {
  padding: $verticalPadding $common-xl-padding;
  height: 100%;

  @if $hasFooter {
    padding-bottom: $form-page-footer-padding;
  }

  @media (max-width: $mobile-max-screen-width) {
    & {
      padding: $verticalPadding $common-md-padding;

      @if $hasFooter {
        padding-bottom: $form-page-footer-padding;
      }
    }
  }
}

// FONTS
@mixin setFontFace($font-family-name, $font-config-src, $font-config-weight: normal, $font-config-style: normal) {
  @font-face {
    font-family: $font-family-name;
    src: url($font-config-src) format('opentype');
    font-weight: $font-config-weight;
    font-style: $font-config-style;
  }
}

@mixin setFontFaces($font-family-name, $font-configs) {
  @each $font-config-src, $font-config-weight, $font-config-style in $font-configs {
    @include setFontFace(
            $font-family-name,
            $font-config-src,
            $font-config-weight,
            $font-config-style
    );
  }
}

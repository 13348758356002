@import "src/variables";
@import "src/themify";

.button {
  text-transform: uppercase;
}

.tableTitle {
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  text-transform: uppercase;
}

.helperToggleText {
  font-size: 15px;
  color: $grey;
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

$page-select-width: 200px;

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .banner {
    background: url($event-catalog-background-image) center;
    background-size: cover;

    &, .title {
      color: white;
    }

    .title {
      margin: 0;
      font-size: 40px;
    }

    .bannerContent {
      min-height: 400px;
      display: flex;
      flex-direction: column;
    }
  }

  .titleContainer {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .subTitleBox {
      margin-top: $common-md-padding;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 6px;
      padding: $common-sm-padding;
      font-weight: bold;

      @include themify {
        color: $primary-theme-color;
      }
    }
  }

  .searchWrapper {
    flex-grow: 1;
    display: flex;
  }

  .sidebar {
    width: $sidebar-width;
    padding: $common-sm-padding $common-sm-padding $common-sm-padding $common-lg-padding;

    & + .content {
      width: calc(100% - #{$sidebar-width});
    }
  }

  .content {
    background-color: white;
    padding: $common-lg-padding;

    @media (max-width: $mobile-max-screen-width) {
      padding: $common-sm-padding;
    }
  }
}

.searchInput {
  background-color: white;
}

.bookmarksRow {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.bookmark {
  @include themify {
    background-color: rgba($primary-theme-color, 0.5);
  }

  color: white;
  border-radius: 20px 20px 0 0;
  width: 25%;
  padding: $common-xs-padding $common-sm-padding;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: $common-xs-padding;
  }

  &.active {
    @include themify {
      background-color: $primary-theme-color;
    }

    cursor: default;
  }

  .count {
    font-size: 50px;
    font-weight: 700;
  }

  .label {
    font-size: 18px;
  }
}

.noData {
  text-align: center;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageSelectWrapper {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: $mobile-max-screen-width) {
  .paginationWrapper {
    margin-left: $page-select-width;
  }

  .pageSelectWrapper {
    width: $page-select-width;
  }
}

.select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selectLabel {
  font-weight: 500;
  font-size: 18px;
  margin-right: $common-sm-padding;
  white-space: nowrap;
}

@import "src/variables";

.table {
  box-shadow: none;
  margin: $common-xs-padding 0 $common-sm-padding;
}

.total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 700;
  font-size: 22px;
  line-height: 35px;

  & > span {
    margin-right: $common-sm-padding;
  }
}

@import "src/variables";
@import "src/mixins";

.card {
  border: 2px solid $light-grey;
  border-radius: 10px;

  min-height: 260px;
  height: 100%;

  .content {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .divider {
    width: 100%;
    margin: 0 0 $common-md-padding;
  }

  .title {
    font-weight: bold;
    font-size: 22px;
    padding: $common-md-padding;
    @include themify {
      color: $primary-theme-color;
    }
  }

  .fullName {
    font-weight: bold;
    font-size: 16px;
    padding: $common-xs-padding $common-sm-padding;
  }

  .addressWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: $common-sm-padding;
  }

  .actionButton {
    margin: $common-xs-padding $common-xs-padding $common-xs-padding auto;

    .icon {
      @include setIconSize(20px);
    }
  }

  .label {
    margin-bottom: 5px;
    border-radius: 0;
    &:last-child {
    border-radius: 0;
      margin-bottom: 0;
    }
  }
}

.spinner {
  height: 100%;
}

@import "src/variables";
@import "src/mixins";

.sectionHeader {
  & > * {
    margin-bottom: $common-xs-padding;
  }

  .sectionCardActionButton {
    text-transform: uppercase;
    font-size: 12px;
  }
}

.sectionDescription {
  margin-top: 0;
  color: $grey;
}

.sectionTable {
  box-shadow: none;

  table {
    th:first-child, td:first-child {
      padding-left: 0;
    }

    th:last-child, td:last-child {
      padding-right: 0;
    }
  }
}

.sectionTableActionButton {
  text-transform: uppercase;
  font-size: 10px;

  svg {
    @include setIconSize(17px);
  }
}

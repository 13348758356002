@import "src/variables";
@import "src/mixins";

.backdrop {
  z-index: 2;
  flex-direction: column;
}

.label {
  font-weight: bold;
  margin-top: $common-sm-padding;
  @include themify {
    color: $primary-theme-color;
    font-size: 22px;
  }
}

.container {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;

  &.loading {
    pointer-events: none;
    max-height: 100vh;
    min-height: 100px;
    overflow: hidden;
  }
}

.filled {
  background-color: white;
  z-index: 2;
}

.spinnerWrapper {
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & ~ * {
    opacity: .2;
  }

  &.filled {
    & ~ * {
      visibility: hidden;
    }
  }
}

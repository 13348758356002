@import "src/variables";
@import "src/mixins";

$type-icon-size: 20px;

.card {
  .cardContent {
    padding: 0;
  }
}

.section {
  padding: $common-xs-padding $common-sm-padding;
}

.title {
  @include setUppercaseTitle;

  font-size: 20px;
  line-height: 32px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.description {
  margin-top: $common-sm-padding;
}

.topInfo {
  font-size: 14px;
  line-height: $type-icon-size;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .typeLabel {
    display: flex;
    align-items: center;
  }

  .typeIcon {
    @include setIconSize($type-icon-size);
    @include themify {
      color: $primary-theme-color;
    }

    margin-right: $common-xs-padding;
  }
}

.footer {
  color: $grey;
  border-top: 1px solid $light-grey;
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

.title {
  @include setUppercaseTitle;

  font-size: 20px;
  line-height: 32px;
  margin-bottom: $common-sm-padding;
}

.card {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.cardContent {
  padding: $common-lg-padding
}

.actionButton {
  text-align: center;

  &:not(:last-child) {
    margin-bottom: $common-xs-padding;
  }
}

.row {
  display: flex;
  align-items: flex-start;
  line-height: 24px;
  font-size: 16px;
  word-break: break-all;

  &:not(:last-child) {
    margin-bottom: $common-xs-padding;
  }

  .icon {
    @include setIconSize(24px);

    margin-right: $common-xs-padding;
    color: $grey;
  }
}

.leftSection {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;

    .typeIcon {
      @include setIconSize(60px);
    }

    .typeLabel {
      text-transform: uppercase;
      margin: $common-xs-padding 0;
      font-size: 14px;
    }

    .calendar {
      align-self: flex-start;
      margin-bottom: -$common-sm-padding;
      z-index: 1;
    }
  }
}

.rightSection {
  display: flex;
  flex-direction: column;
}

.accessDateLabel {
  font-weight: 700;
}

.infoSection {
  color: $grey;

  .infoSectionRow {
    width: 100%;
  }
}

.table {
  box-shadow: none;
}

.guestLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: $common-sm-padding;
  margin-bottom: 1px;

  @include themify {
    background-color: $primary-theme-color;
  }
}

@import "src/variables";
@import "src/mixins";

.card {
  .sectionTitle {
    margin: 0;
  }

  .cardContent {
    padding-bottom: $common-sm-padding;
  }
}

.menuLabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menuLabelIcon {
  @include setIconSize(22px);

  margin-left: $common-xs-padding;
}

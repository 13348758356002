@import "src/variables";

.companySelect {
  margin-top: $common-md-padding;
}

.tabWithCounter {
  display: inline-block;
}

.countBadge {
  margin-left: $common-xs-padding;
}

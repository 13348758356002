@import "src/variables";
@import "src/mixins";
@import "src/themify";

.title {
  font-size: 17px;
  margin-bottom: $common-sm-padding;
  line-height: 0;
}

.divider {
  width: 100%;
  margin-bottom: $common-xs-padding;

  @include themify {
    background-color: $primary-theme-color;
  }
}

.subTitle {
  font-size: 15px;
}

.section {
  margin-top: $common-xs-padding;
}

.stepper {
  margin-bottom: $common-xl-padding;
}

.button {
  margin-left: auto;
}

.hotelName {
  font-style: italic;
  margin-bottom: $common-sm-padding;
}

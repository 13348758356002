@import "src/variables";
@import "src/mixins";

.formFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;

  .content {
    width: 100%;
    background-color: white;
    box-shadow: 0px -10px 30px rgba(black, 0.05);
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: $common-md-padding;
    padding-bottom: $common-md-padding;
  }

  .leftButtonWrapper {
    margin-right: $common-md-padding;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .fullWidth {
    @include setPageContentStyles(false, 0);
  }

  .submitButton {
    white-space: nowrap;
  }
}

$action-button-wrapper-width: 80px;

.status {
  width: 100%;
  max-width: 140px
}

.actionButtonWrapper {
  width: $action-button-wrapper-width;
  max-width: $action-button-wrapper-width;
  min-width: $action-button-wrapper-width;

  .actionButton {
    width: 100%;
  }
}


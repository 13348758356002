@import "src/variables";

$search-button-border-radius: calc(#{$common-xs-padding} / 2);

.searchButton {
  border-radius: $search-button-border-radius;
  height: 100%;
  max-height: 56px;
}

.searchButtonWrapper {
  background: white;
  border-radius: $search-button-border-radius;
  margin-left: calc(#{$search-button-border-radius} * -1);
  z-index: 1;
}

.tag {
  word-break: break-word;
  max-width: 80%;
}

@import "src/variables";
@import "src/mixins";

.applyButton {
  height: 56px;
}

.buttonsWrapper {
  justify-content: flex-start;
  display: flex;
}

.deleteButton {
  height: 56px;
  font-size: 10px;
  margin-left: $common-sm-padding;

  svg {
    @include setIconSize(20px);
  }
}

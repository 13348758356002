@import "src/variables";
@import "src/mixins";

$logo-size: 100px;

.darkSection {
  background-color: $lightest-grey;
}

.sectionContent {
  padding-top: $common-lg-padding;
  padding-bottom: $common-xl-padding;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
}

.notFoundSection {
  text-align: center;
}

.logo {
  margin-top: 20px;
  @include setSize($logo-size);
  align-self: flex-start;
  display: block;

  img {
    height: 100%;
  }
}

.pageTitle {
  margin: 0;
  line-height: 40px;
}

.createCampaignButtonWrapper {
  margin-left: auto;
}

.backLink {
  display: flex;
  align-items: center;
  margin-right: $common-md-padding;
  font-size: 14px;

  .backIcon {
    @include setIconSize(14px);
  }
}

.header {
  padding-top: $common-md-padding;
  padding-bottom: 0;
}

.skeletonCard {
  padding-bottom: 100%;
}

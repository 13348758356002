@import "src/variables";
@import "src/mixins";
@import "src/themify";

$action-button-width: 64px;

.item {
  width: 100%;
}

.divider {
  width: 100%;
  margin: $common-sm-padding 0;
}

.title {
  @include themify {
    color: $primary-theme-color;
  }
  display: flex;
  font-weight: bold;
  font-size: 18px;

  .status {
    margin-left: $common-xs-padding;
  }

}

.sectionContent {
  padding-top: $common-xl-padding;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
}

.descriptionRow {
  display: flex;
  align-items: center;

  .icon {
    @include setIconSize(22px);

    margin-right: $common-xs-padding;
  }

  .hint {
    margin-left: calc(#{$common-xs-padding} / 2);
    font-size: 12px;
  }
}

.actionButton {
  width: $action-button-width;
  margin-left: auto;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  flex-direction: column;
  align-items: center;

  .nameLabel {
    font-size: 8px;
  }
}

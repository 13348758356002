@import "src/variables";

.button {
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .helperText {
    font-style: italic;
    font-size: 12px;
  }
}

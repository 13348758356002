@import "src/variables";
@import "src/mixins";

.dropzone {
  @include themify {
    background-color: $primary-theme-color;
  }
  order: 1;
  padding: (calc(#{$common-xs-padding} / 2)) $common-xs-padding;
  width: auto;
  min-height: 20px;
  border: 0;
  border-radius: 4px;

  .dropzoneTextContainer {
    display: flex;
    align-items: center;
  }

  .dropzoneText, .dropzoneIcon {
    color: white;
    margin: 0;
    padding: 0;
  }

  .dropzoneIcon {
    @include setIconSize(18px);

    order: 0;
    margin-right: $common-xs-padding;
  }

  .dropzoneText {
    order: 1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.75;
  }
}

.helperText {
  order: 2;
  margin-top: $common-xs-padding;
  width: 100%;
  flex-shrink: 0;
  text-align: right;
}

@import "src/variables";
@import "src/mixins";

$preview-image-size: 240px;

.content {
  display: flex;
  justify-content: center;
}

.dropzoneWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  .dropzone {
    @include setSize($preview-image-size);

    min-height: $preview-image-size;
    flex-grow: 1;
  }

  .dropzoneText {
    margin-left: $common-md-padding;
    margin-right: $common-md-padding;
  }

  .previewContainer {
    @include setSize($preview-image-size);

    min-height: $preview-image-size;
    margin-left: $common-md-padding;
    z-index: 0;
  }
}

.previewImageContainer {
  img {
    @include setSize($preview-image-size);

    object-fit: cover;
  }
}

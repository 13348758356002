@import "src/variables";
@import "src/mixins";
@import "src/themify";

$type-icon-size: 28px;
$home-icon-size: 24px;

.breadcrumbsWrapper {
  padding-top: $common-md-padding;
  padding-bottom: $common-md-padding;
}

.breadcrumbs {
  font-size: 16px;
  line-height: $home-icon-size;

  li {
    height: $home-icon-size;
  }

  .homeIcon {
    @include setIconSize($home-icon-size);
  }

  .currentLink {
    cursor: initial;
    text-decoration: none;
  }
}

.wrapper {
  display: flex;
}

.sidebar {
  z-index: 10;
}

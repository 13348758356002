@import "src/variables";
@import "src/mixins";

.promotion {
  flex-grow: 2;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.benefits {
  li {
    display: flex;
    line-height: 22px;

    &:not(:last-child) {
      margin-bottom: $common-sm-padding;
    }

    svg {
      flex-shrink: 0;
      margin-right: $common-xs-padding;
    }
  }
}

.review {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  p {
    margin: $common-sm-padding 0 $common-lg-padding;
  }

  .reviewAvatar {
    @include setSize(116px);

    box-shadow: 0 4px 4px rgba(black, 0.25);
    margin-bottom: $common-lg-padding;
  }

  .reviewTitle {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
  }

  .reviewAuthorName {
    font-style: italic;
  }

  .reviewAuthorPosition {
    opacity: 0.5;
  }

  @media (max-width: $mobile-max-screen-width) {
    .reviewAvatar, .reviewTitle {
      align-self: center;
    }

    .reviewAvatar {
      margin-top: $common-lg-padding;
    }
  }
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

.container {
  h2 {
    margin: 0;
  }

  p > span {
    line-height: 26px;
  }

  .dividerWrapper {
    padding-top: 0;
    padding-bottom: 0;

    .divider {
      margin: $common-xs-padding 0 0;
      width: 100%;
      height: 1px;

      @include themify {
        background-color: $primary-theme-color;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .socialLink {
    display: flex;
    align-items: center;

    .icon {
      margin-right: $common-xs-padding;
    }
  }

  .segmentLabel {
    margin-bottom: $common-xs-padding;
    margin-right: $common-xs-padding;
  }
}

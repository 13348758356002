@import "src/variables";

.select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selectLabel {
  font-weight: 500;
  font-size: 18px;
  margin-right: $common-md-padding;
}

.tableTitle {
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  text-transform: uppercase;
}

.search {
  margin-left: auto;
}

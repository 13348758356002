@import "src/variables";
@import "src/themify";

.link {
  cursor: pointer;
  text-decoration: underline;
}

.helperText {
  @include themify {
    color: $primary-theme-color;
  }
}

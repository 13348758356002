@import "src/variables";
@import "src/mixins";
@import "src/themify";

.id {
  @include themify {
    color: $primary-text-color;
  }

  font-weight: 700;
  font-size: 18px;
}

.submitButton {
  margin-left: $common-sm-padding;
}

@import 'src/variables';

.buttonGroup {
  gap: $common-xs-padding;
}

.button {
  border: none;
  cursor: pointer;
  padding: 0;
}

.label {
  cursor: pointer;
}

.linkButton {
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
}

.linkButtonWrapper {
  margin-left: auto;
}


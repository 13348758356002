@import "src/variables";
@import "src/themify";

.header {
  @include themify {
    background-color: rgba($primary-theme-color, 0.06);
  }
}

.title {
  @include themify {
    color: $primary-theme-color;
  }

  display: flex;
  align-items: center;

  .headerIcon {
    margin-right: $common-xs-padding;
  }
}

.button {
  margin-left: auto;
}


@import "src/variables";
@import "src/mixins";
@import "src/themify";

.status {
  display: flex;
  align-items: center;
  margin-bottom: $common-sm-padding;

  .value {
    line-height: 0;
    margin-left: $common-xs-padding;
  }
}

.title, .comment {
  font-weight: bold;
  font-size: 19px;
  @include themify {
    color: $primary-theme-color;
  }
}


.divider {
  margin: $common-xs-padding 0;
  width: 100%;
  height: 1px;

  @include themify {
    background-color: $primary-theme-color;
  }
}

.comment {
  display: inline-block;
  margin-top: $common-sm-padding;
}

@import "src/variables";
@import "src/themify";

.card {
  max-width: 645px;

  .checkboxLabel {
    @include themify {
      color: $primary-text-color;
    }

    font-size: 16px;

    .date {
      font-weight: 700;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-bottom: $common-sm-padding;

    &:not(:last-child) {
      margin-right: $common-md-padding;
    }
  }

  .submitButton {
    margin-left: auto;
  }

  @media (max-width: $mobile-max-screen-width) {
    flex-direction: column;

    & > * {
      &:not(:last-child) {
        margin-right: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .submitButton {
      margin-left: inherit;
    }
  }
}

.description {
  margin-bottom: 0;
}

.turnOffButton {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  color: $grey-disabled;
  padding-left: 0;
  padding-right: 0;
}

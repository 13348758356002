@import "src/variables";

.value {
  font-size: 30px;
}

.label {
  font-size: 12px;
  margin-top: $common-sm-padding;
  margin-bottom: 0;
}

.tilesWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 .tile {
   margin-bottom: 5px
 }
}

.helperText {
  font-style: italic;
  color: #717273;
  font-size: 14px;
}

.companySelect {
  margin-top: 25px;
}

@import "src/variables";

.label {
  font-weight: 700;
  line-height: 24px;
}

.value {
  line-height: 24px;
  word-wrap: break-word;
  & > span {
    width: 100%;
    display: inline-block;
  }
}

.billToValue {
  word-break: break-word;
  white-space: pre-wrap;
  hyphens: auto;
}

.paymentInfo {
  padding-top: $common-sm-padding;
  padding-bottom: $common-sm-padding;
}

.printIframe {
  height: 0;
  width: 0;
  position: absolute;
}

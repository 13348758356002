@import "src/variables";
@import "src/mixins";
@import "src/themify";

.divider {
  width: 100%;
  height: 1px;

  @include themify {
    background-color: $primary-theme-color;
  }
}

.description {
  font-style: italic;
  color: $dark-grey;
  margin: 0;
}

.name {
  display: block;
  margin: $common-sm-padding 0 $common-xs-padding;
  font-weight: bold;

  @include themify {
    color: $primary-theme-color;
  }
}

.type {
  display: block;
  margin: $common-xs-padding 0 $common-xs-padding;
}

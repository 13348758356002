@import "src/variables";

.formControl {
  position: relative;

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  .label {
    display: inline-block;
    padding: 0 5px;
    font-size: 13px;
    position: absolute;
    top: 0;
    left: $common-sm-padding;
    background-color: white;
    transform: translateY(-50%);
  }
}

@import "src/variables";
@import "src/mixins";

.personWrapper {
  font-size: 18px;

  .person {
    padding-right: $common-sm-padding;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .name, .role {
    margin: $common-xs-padding auto 0;
  }

  .avatar {
    @include setSize(100px);

    margin: 0 auto;

    svg {
      @include setIconSize(50px);
    }
  }

  @media (max-width: $mobile-max-screen-width) {
    border: 0;

    .person {
      padding: 0;
    }
  }
}

.contactsWrapper {
  border-left: 1px solid $grey;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: calc($common-sm-padding * -1);
}

.row {
  display: inline-flex;
  align-items: flex-start;
  line-height: 28px;
  font-size: 18px;
  word-break: break-all;
  margin-left: $common-sm-padding;

  &:not(:last-child) {
    margin-bottom: $common-xs-padding;
  }

  .icon {
    @include setIconSize(27px);

    margin-right: $common-xs-padding;
    color: $grey;
  }

  .locationValue {
    width: 100%;
  }

  .location {
    display: inline-block;
    width: 100%;
  }
}

.subTitle {
  font-size: 17px;
  margin: $common-sm-padding 0;
}

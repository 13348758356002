@import "src/mixins";

$avatar-size: 160px;

.avatar {
  @include setSize($avatar-size);

  border: 3px solid white;

  svg {
    @include setIconSize(calc(#{$avatar-size} / 2));
  }
}

.skeleton {
  @include setSize($avatar-size);
}

.lightSkeleton {
  background-color: white;
}

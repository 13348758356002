@import "src/variables";
@import "src/mixins";
@import "src/themify";

.link {
  @include themify {
    @include setLinkStyles($primary-text-color);
  }

  font-weight: 500;
  font-size: 14px;

  &.disabled {
    color: $grey-disabled;
    pointer-events: none;
  }
}

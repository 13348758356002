@import "src/variables";

$action-button-width: 64px;
$action-button-wrapper-width: $action-button-width + $common-sm-padding;

.actionButton {
  width: $action-button-width;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  flex-direction: column;
  align-items: center;

  .nameLabel {
    font-size: 8px;
  }
}

.actionButtonWrapper {
  width: $action-button-wrapper-width;
  max-width: $action-button-wrapper-width;
  min-width: $action-button-wrapper-width;

  .actionButton {
    width: 100%;
  }
}


@import "src/variables";

$action-button-width: 64px;

.status {
  width: 100%;
  max-width: 140px
}

.actionButton {
  width: $action-button-width;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  flex-direction: column;
  align-items: center;

  .nameLabel {
    font-size: 8px;
  }
}

@import "src/variables";

.summaryRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  &:not(:last-of-type) {
    margin-bottom: $common-sm-padding;
  }

  span + span {
    margin-left: $common-xs-padding;
  }

  .price {
    font-style: italic;
  }
}

.divider {
  margin: 20px 0;
}

.totalSummaryRow {
  font-weight: 700;
  font-size: 22px;
}

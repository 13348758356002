@import "src/mixins";

.deleteButton {
  text-transform: uppercase;
  font-size: 10px;

  svg {
    @include setIconSize(20px);
  }
}

@import "src/themify";
@import "src/mixins";
@import "src/variables";

.donationItem {
  display: flex;
  flex-direction: column;
}

.donationPriceSection {
  margin-top: auto;
}

.logo {
  width: 200px;
  height: 100px;
  background-color: transparent;
  justify-content: flex-start;

  @include themify {
    color: $primary-theme-color;
  }
}

.logoIcon {
  @include setSize(50px);
}


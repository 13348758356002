@import "src/variables";

.file {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: calc(#{$common-xs-padding} / 2);
  }

  .fileIcon {
    margin-right: calc(#{$common-xs-padding} / 2)
  }
}

@import 'src/variables';

.label {
  font-weight: 700;
  line-height: 24px;
  text-align: right;
}

.value {
  line-height: 24px;
  word-wrap: break-word;
  & > span {
    width: 100%;
    display: inline-block;
  }
}

.statementModal {
  max-width: 650px;
  .statementModalContent {
    padding-bottom: $common-xs-padding;
  }
}

.date {
  max-width: 200px;
}


@import "src/variables";
@import "src/mixins";

.container {
  padding-top: $common-sm-padding;
}

.cardHeader {
  font-size: 20px;

  div {
    text-transform: none;
  }
}

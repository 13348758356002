@import "src/variables";

.checkboxes {
  display: flex;
  flex-direction: column;
}

.deleteButton {
  margin: 0 auto 0 $common-sm-padding;
}

.helperText {
  font-style: italic;
  color: $grey-disabled;
  font-size: 13px;
  margin-left: 35px;
  margin-bottom: 10px;
}

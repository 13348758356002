@import "src/variables";

.adornment {
  margin-left: 0;
}

.keyboardButton {
  padding: calc(#{$common-xs-padding} / 2)
}


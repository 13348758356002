@import "src/mixins";
@import "src/variables";

.contentBorder {
  box-shadow: $common-card-box-shadow;
  padding: $common-md-padding;
  margin-bottom: $common-lg-padding;
}

.contentBlock {
  margin-bottom: $common-md-padding;
}

.radioGroup {
  flex-direction: row;
  margin-bottom: $common-lg-padding;
}

.fileUploadSection {
  padding-top: 0 !important;
}

.pullRight {
  text-align: right;
  @media (max-width: $mobile-max-screen-width) {
    text-align: left;
  }
}

.createReportButton {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;

  @media (max-width: $mobile-max-screen-width) {
    display: block;
  }
}

.createNewExpenseButton {
  margin-left: auto;
  margin-right: $common-sm-padding;
}

.pageTitleSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $common-lg-padding;
  h1 {
    margin-bottom: 0;
  }
  @media (max-width: $mobile-max-screen-width) {
    display: block;
    h1 {
      margin-bottom: $common-md-padding;
    }
  }
}

.alignBottom {
  align-items: flex-end;
}

.tableTitle {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-top: 0;
  text-transform: uppercase;
}

.buttonPadding {
  button {
    padding-left: $common-md-padding;
    padding-right: $common-md-padding;
  }
}

.buttonGroup {
  display: flex;
  gap: $common-sm-padding;
  justify-content: center;
}

.tableButton {
  padding-top: 0;
  padding-bottom: 0;
}

.delegationStatus {
  font-weight: bold;
  padding-top: $common-xs-padding;
  padding-bottom: $common-xs-padding;
}

.expenseReportStatus {
  @extend .delegationStatus;
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.reportPageStatus {
  display: inline-block;
  min-width: 150px;
  letter-spacing: .1875rem;
  text-transform: uppercase;
}

.total {
  @extend .pullRight;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: $common-sm-padding;
}

.backLink {
  display: flex;
  align-items: center;
  font-size: 1rem;

  .backIcon {
    @include setIconSize(1rem);
  }
}

.delegatorName {
  color: $error-red;
  font-size: 1.25rem;
  font-weight: bold;
}

.reviewHeading {
  color: $error-red;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: $common-sm-padding;
}

.reviewLabel {
  font-weight: bold;
  margin-bottom: $common-xs-padding;
}

.nowrap {
  white-space: nowrap;
}

.alert {
  margin-top: $common-md-padding;
  margin-bottom: $common-md-padding;
  max-width: 700px;
  a {
    font-weight: bold;
    text-decoration: underline;
  }

  @media (max-width: $mobile-max-screen-width) {
    max-width: 100%;
  }
}

:export {
  primary: $primary-blue;
}

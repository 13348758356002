@import "src/variables";
@import "src/themify";

$count-badge-size: 24px;

.badge {
  @include themify {
    background-color: $primary-theme-color;
  }

  display: inline-block;
  color: white;
  text-align: center;
  min-width: $count-badge-size;
  height: $count-badge-size;
  line-height: $count-badge-size;
  font-size: calc(#{$count-badge-size} / 2);
  padding: 0 (calc(#{$common-xs-padding} / 2));
  border: 0;
  border-radius: calc(#{$count-badge-size} / 2);
  font-weight: 700;

  &.light {
    @include themify {
      color: $primary-theme-color;
    }

    background-color: white;
  }
}

@import "src/variables";

.container {
  padding: $common-xl-padding 0 $form-page-footer-padding;
}

.alert {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  margin-bottom: $common-md-padding;
}

@import "src/variables";

.speaker {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.additionInfoBox {
  margin-top: $common-lg-padding;
  color: white;
  background: $bright-green;

  .title {
    display: flex;
    align-items: center;
    font-size: 22px;

    svg {
      margin-right: $common-xs-padding;
    }

    .spinner {
      height: auto !important;
    }
  }

  .value {
    margin-top: $common-md-padding;
    display: block;
    font-size: 18px;
    cursor: pointer;
  }
}

@import "src/variables";
@import "src/themify";

.divider {
  margin: $common-xs-padding 0;
  width: 100%;
  height: 1px;

  @include themify {
    background-color: $primary-theme-color;
  }
}

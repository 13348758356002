@import 'src/variables';
@import 'src/themify';
@import "src/mixins";

.header {
  @include setUppercaseTitle;
  @include themify {
    border-bottom: 2px solid $primary-theme-color;
  }

  padding: 0 0 $common-xs-padding;
}

.headerAction {
  margin: 0;
  align-self: unset;
}

.headerContent {
  display: inline-flex;
  align-items: center;

  & > * {
    margin: 0 $common-xs-padding 0 0;
  }
}

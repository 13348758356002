.button {
  margin: 0 10px 10px 0;
  border-radius: 20px;
}

.link {
  line-height: 0
}

.largeSize {
  .button {
    width: 100px;

    svg {
      height: 40px;
      width: 40px;
    }
  }
}

.mediumSize {
  .button {
    width: 80px;

    svg {
      height: 35px;
      width: 35px;
    }
  }
}

.smallSize {
  .button {
    width: 65px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

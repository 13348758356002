@import "src/variables";
@import "src/themify";
@import "src/mixins";

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: $grey;

  .avatar {
    @include setSize(42px);

    border: 0;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: $common-sm-padding;

    svg {
      @include setIconSize(26px);
    }
  }

  .information {
    display: flex;
    flex-direction: column;
    padding: 3px 0;
  }

  .name {
    @include themify {
      color: $primary-text-color;
    }

    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
  }

  .subTitle {
    margin-bottom: 0;
  }

  .divider {
    margin-bottom: $common-xs-padding;
    @include themify {
      background-color: $primary-theme-color;
    }
  }

  .memberHistory {
    margin-left: $common-md-padding;


    .member {
      display: flex;
      flex-direction: column;

      .divider {
        width: 100%;
        background-color: $light-grey;
      }
    }
  }
}

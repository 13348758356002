@import "src/themify";
@import "src/mixins";
@import "src/variables";

.completed {
  z-index: 1;
  font-size: 30px;
}

.step {
  @include themify(true) {
    background-color: $primary-theme-color;
  }

  z-index: 1;
  color: white;
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  .icon {
    font-size: 35px;
  }
}

.disabled {
  @include themify(true) {
    background-color: $grey-disabled;
  }
}

.connector {
  top: 29px;
  z-index: 0;
  background-color: $grey-disabled;
  height: 3px;

  .line {
    display: none;
  }

}

.active {
  @include themify(true) {
    background-color: lighten($primary-theme-color, 20);
  }
  height: 4px;
}

.label {
  .activeLabel {
    font-weight: bold;
  }
}

@import "src/variables";

.label {
  margin-top: 0;
}

.helperText {
  margin-left: $common-sm-padding;
  margin-right: $common-sm-padding;
}

.disabled {
  opacity: .6;
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

.card {
  border: 3px dashed $light-grey;
  border-radius: 4px;
  padding: 0;
  min-height: 260px;
  height: 100%;
  cursor: pointer;

  .content {
    height: 100%;
    padding: 0;
  }

  .actionArea {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon {
    color: $light-grey;

    svg {
      width: 80px;
      height: 80px;
    }
  }

  .title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    @include themify {
      color: $primary-theme-color;
    }
  }
}

.spinner {
  height: 100%;
}

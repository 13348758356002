@import "src/variables";
@import 'src/themify';

.noData {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  &.selected, &.selected:hover {
    @include themify {
      background-color: lighten($primary-theme-color, 75%);
    }
  }
}

.paginationContainer {
  margin: $common-sm-padding $common-lg-padding;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton {
  margin: $common-sm-padding 0;
}

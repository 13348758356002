.wrapper {
  width: 100%;
  display: flex;
}

.sidebar {
  z-index: 2;
}

.input {
  border-radius: 20px;
}

@import "src/variables";

.content {
  padding: $common-sm-padding $common-lg-padding $common-lg-padding;
}

.container {
  padding-bottom: 0;
}

.noData {
  width: 100%;
  padding: $common-lg-padding $common-sm-padding;
  line-height: 24px;
  text-align: center;
}

.selectTitle {
  margin-top: 0;
}


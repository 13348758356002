@import "src/variables";
@import "src/mixins";

.container {
  display: flex;

  .page {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: $mobile-max-screen-width) {
    & {
      flex-direction: column;

      .page {
        flex: 1;
      }
    }
  }
}

.content {
  @include setPageContentStyles(true);
}

.desktopContainer {
  min-height: 100vh;
}

.alert {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

.quickLinks {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin: 0 $common-lg-padding $common-xs-padding 0;
  }

  .quickLinkButton {
    min-height: 28px;
    font-weight: 700;
    font-size: 15px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.label {
  @include themify {
    color: $primary-text-color;
  }

  font-weight: 700;
  font-size: 18px;
}

.cardContent {
  .button {
    margin-top: $common-md-padding;
  }
}

.viewAllButton {
  @include setLinkStyles(inherit);

  text-decoration: none;
  margin-left: $common-md-padding;

  &.disabled {
    color: $grey-disabled;
    pointer-events: none;
  }
}

.divider {
  margin: $common-sm-padding 0;
}

.cardNumberInfo {
  display: flex;
  justify-content: flex-end;

  .cardNumber {
    text-transform: uppercase;
  }
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

.profile {
  h2, h3 {
    margin: 0;
  }

  p > span {
    line-height: 26px;
  }

  .id {
    @include themify {
      color: $primary-text-color;
    }

    font-weight: 700;
    font-size: 18px;
    margin: 0;
  }


  .divider {
    margin: $common-xs-padding 0 $common-sm-padding;
    width: 100%;
    height: 1px;

    @include themify {
      background-color: $primary-theme-color;
    }

  }

  .avatarSection {
    .avatar {
      position: relative;
      width: 300px;

      .avatarImage {
        @include setSize(300px);
      }

      .editButton {
        @include setSize(50px);

        box-shadow: 0 4px 4px rgba(black, 0.25);
        position: absolute;
        right: 20px;
        bottom: 20px;
        background-color: white;

        .editButtonIcon {
          @include setSize(24px);
        }
      }
    }

    .toggle {
      justify-content: center;
    }
  }

  .helperText {
    font-style: italic;
    color: gray;
    display: block;
    margin-top: $common-xs-padding;
  }

  .userTitle {
    display: flex;
    justify-content: space-between;
  }

  .spinner {
    height: 100%;
  }

  .card {
    height: 100%;

    .cardContent {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        margin-bottom: $common-sm-padding;
      }
    }
  }

  .toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $common-sm-padding;
    margin-left: auto;

    @include themify {
      color: $primary-theme-color;
    }
  }
}

.tooltipWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: $common-sm-padding;
}

.tooltipContent {
  max-width: 600px;
}

.tooltip {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    margin: 0 $common-xs-padding;
    @include themify {
      color: $primary-theme-color;
    }
  }
}

@import 'src/variables';
@import 'src/mixins';

$medium-switch-width: 100px;
$medium-switch-height: 38px;
$medium-switch-track-width: 76px;
$medium-switch-thumb-size: 34px;
$medium-switch-thumb-padding: calc((#{$medium-switch-height} - #{$medium-switch-thumb-size}) / 2);

$small-switch-width: 66px;
$small-switch-height: 26px;
$small-switch-track-width: 50px;
$small-switch-thumb-size: 22px;
$small-switch-thumb-padding: calc((#{$small-switch-height} - #{$small-switch-thumb-size}) / 2);

  .rootSmall {
    height: $small-switch-height;
    width: $small-switch-width;
    padding-top: 0;
    padding-bottom: 0;


    .switchBaseSmall {
      padding: $small-switch-thumb-padding;
      left: 7px;

      .thumbSmall {
        @include setSize($small-switch-thumb-size);
      }

      .thumbSmall,
      &.checkedSmall .thumbSmall {
        background-color: white;
      }

      &.checkedSmall {
        transform: translateX(calc(#{$small-switch-track-width} - #{$small-switch-thumb-size} - #{$small-switch-thumb-padding} * 2));

        & + .trackSmall {
          opacity: 1;
        }
      }

      &.disabledSmall + .trackSmall {
        opacity: 0.5;
      }

      &.disabledSmall:not(.checkedSmall) + .trackSmall {
        background-color: $grey;
      }
    }

    .trackSmall {
      border-radius: calc(#{$small-switch-height} / 2);
      width: $small-switch-track-width;
    }
  }

  .rootMedium {
    height: $medium-switch-height;
    width: $medium-switch-width;
    padding-top: 0;
    padding-bottom: 0;
  }

  .switchBaseMedium {
    padding: $medium-switch-thumb-padding;
    left: 12px;

    .thumbMedium {
      @include setSize($medium-switch-thumb-size);
    }

    .thumbMedium,
    &.checkedMedium .thumbMedium {
      background-color: white;
    }

    &.checkedMedium {
      transform: translateX(calc(#{$medium-switch-track-width} - #{$medium-switch-thumb-size} - #{$medium-switch-thumb-padding} * 2));

      & + .trackMedium {
        opacity: 1;
      }
    }

    &.disabledMedium + .trackMedium {
      opacity: 0.5;
    }

    &.disabledMedium:not(.checkedMedium) + .Medium {
      background-color: $grey;
    }
  }

  .trackMedium {
    border-radius: calc(#{$medium-switch-height} / 2);
    width: $medium-switch-track-width;
  }

@import "src/variables";
@import "src/mixins";
@import "src/themify";

$selected-label-height: 24px;

.card {
  @include setCardStyles(true);

  border-color: $grey;
  height: 100%;

  &.selected {
    position: relative;
    box-shadow: 0 15px 30px rgba(black, 0.5);
    border-width: 2px;
    overflow: initial;

    @include themify {
      border-color: $primary-theme-color;
    }

    .selectedLabel {
      position: absolute;
      display: inline-block;
      color: white;
      top: calc(-#{$selected-label-height} / 2);
      left: 50%;
      transform: translateX(-50%);
      min-width: 120px;
      padding: 0 $common-md-padding;
      line-height: $selected-label-height;
      font-weight: 500;
      border: 0;
      border-radius: 6px;
      text-align: center;
      white-space: nowrap;

      @include themify {
        background-color: $primary-theme-color;
      }
    }
  }
}

.content {
  height: 100%;
  padding: $common-md-padding;

  .info {
    height: 100%;
  }

  .textSection {
    width: 100%;
  }

  .description, .title {
    text-align: center;
  }

  .title {
    @include setUppercaseTitle;

    font-size: 18px;
  }

  .price {
    font-size: 24px;
    display: inline-flex;
    align-items: baseline;

    .period {
      font-size: 18px;
    }
  }

  .divider {
    width: 100%;
    max-width: 160px;
    margin: 0 auto;

    @include themify {
      background-color: $primary-theme-color;
    }
  }

  .button {
    margin-top: $common-sm-padding;
  }

  &.nonEditable {
    .divider {
      max-width: 100%;
      height: 2px;
    }

    .description, .title {
      text-align: left;
    }
  }
}

$max-table-rows: 8;
$row-height: 59px;

.link {
  cursor: pointer;
}

.uploadInput {
  display: none;
}

.table {
  max-height: $row-height * $max-table-rows;
}

@import "src/variables";

.button {
  height: 58px;
  width: auto;

  @media (max-width: $mobile-max-screen-width) {
    width: 100%;
    height: inherit;
  }
}

@import "src/variables";
@import "src/mixins";

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    @include setUppercaseTitle;

    font-size: 20px;
    line-height: 32px;
  }
}

.description {
  margin-top: $common-sm-padding;
}

.divider {
  margin: $common-xs-padding 0;
  width: 100%;
}

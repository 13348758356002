@import "src/variables";
@import "src/mixins";

.container {
  color: black;
  display: flex;

  .image {
    width: 100%;
    height: 100%;

    @media (max-width: $mobile-max-screen-width) {
      max-height: 350px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: $common-md-padding;

    .logo {
      width: 130px;
      height: 130px;
    }

    .wrapper {
      min-height: 230px;
      max-height: 230px;
      overflow-y: auto;

      .title {
        font-size: 32px;
        color: black;

        font-weight: bold;
      }
    }

    .box {
      display: flex;
      flex-direction: column;
      color: black;
      height: 95px;
      background-color: white;
      padding: $common-sm-padding;
      margin: 2px;
      white-space: nowrap;

      .label, .value {
        font-weight: bold;
      }

      .label {
        color: $grey;
        text-transform: uppercase;
        padding-bottom: $common-xs-padding;
      }

      .value {
        font-size: 32px
      }
    }
  }
}

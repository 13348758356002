@import "src/variables";
@import "src/mixins";
@import "src/themify";

.title {
  font-size: 17px;
  margin-bottom: $common-sm-padding;
  line-height: 0;
}

.divider {
  width: 100%;
  margin-bottom: $common-lg-padding;

  @include themify {
    background-color: $primary-theme-color;
  }
}

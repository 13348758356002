@import "src/variables";

.row {
  display: flex;
  justify-content: stretch;
  align-items: baseline;
  margin-bottom: $common-xs-padding;

  .label {
    margin: 0;
    max-width: 40%;
    width: 100%;
    font-weight: 700;
  }

  .value {
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
}

.tableTitle {
  text-transform: none;
  margin-top: $common-md-padding;
}

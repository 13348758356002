@import "src/variables";
@import "src/mixins";
@import "src/themify";

.uploadInput {
  display: none;
}

.formControl {
  display: flex;
  align-items: flex-start;
}

.dropzone {
  @include themify {
    background-color: $primary-theme-color;
  }

  order: 1;
  padding: (calc(#{$common-xs-padding} / 2)) $common-xs-padding;
  width: auto;
  min-height: 20px;
  border: 0;
  border-radius: 4px;

  .dropzoneTextContainer {
    display: flex;
    align-items: center;
  }

  .dropzoneText, .dropzoneIcon {
    color: white;
    margin: 0;
    padding: 0;
  }

  .dropzoneIcon {
    @include setIconSize(18px);

    order: 0;
    margin-right: $common-xs-padding;
  }

  .dropzoneText {
    order: 1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.75;
  }

  &.disabled {
    box-shadow: none;
    background-color: $mui-disabled-bg-color;

    .dropzoneText, .dropzoneIcon {
      color: $mui-disabled-color;
    }
  }
}

.previewContainer {
  order: 0;
  margin-bottom: $common-xs-padding;
}

.helperText {
  order: 2;
  margin-top: $common-xs-padding;
}

.chip {
  @include themify {
    border-color: $primary-theme-color;
  }
}

.sectionTable {
  box-shadow: none;

  table {
    th:first-child, td:first-child {
      padding-left: 0;
    }

    th:last-child, td:last-child {
      padding-right: 0;
    }
  }
}

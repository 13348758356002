@import "src/variables";
@import "src/mixins";
@import "src/themify";

.priceCol {
  margin-left: auto;
  text-align: right;
}

.title {
  text-transform: uppercase;
  margin: 0;
}

.subtotalPrice {
  margin-top: $common-md-padding;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title, .price {
  font-size: 20px;
  line-height: 34px;
  font-weight: bold;
}

.sessionsTable {
  margin-top: $common-sm-padding;
  box-shadow: none;
}

.sessionsTableCell {
  font-size: 14px;
}

.manageSessionsButton, .addGuestButton {
  margin-bottom: $common-sm-padding;
}

.card {
  .cardContent {
    padding: $common-lg-padding;
  }
}

.guestLabel {
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: $common-sm-padding;

  @include themify {
    background-color: $primary-theme-color;
  }
}

@import "src/themify";

.label {
  @include themify {
    color: $primary-theme-color;
  }

  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-top: 11px; // depends on input height
}

@import "src/themify";

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  @include themify(true) {
    color: $primary-theme-color;
  }
}

@each $theme, $colors in $themes {
  :global(.theme-#{$theme}) {
    font-family: map-get($colors, 'font-family');

    h1, h2, h3, h4, h5, h6 {
      font-family: map-get($colors, 'heading-font-family');
    }
  }
}

@import "src/variables";

.radioGroup {
  flex-direction: row;
  margin-bottom: $common-lg-padding;
}

.address {
  display: flex;
  flex-direction: column;
}

.selectAddressWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.addAddressButton {
  margin: $common-sm-padding 0;
}

.label {
  margin-left: 10px;
}

.skeletonWrapper {
  margin-left: $common-lg-padding;
  width: 100%;
}

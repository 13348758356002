@import "src/variables";

.title {
  display: flex;
  align-items: center;

  .headerIcon {
    margin-right: $common-xs-padding;
    color: $green;
  }
}

.description {
  line-height: 30px;
  margin-bottom: $common-lg-padding;
}

.email {
  font-weight: 500;
}

.row {
  display: flex;
  justify-content: stretch;
  align-items: baseline;
  margin-bottom: $common-xs-padding;

  .label {
    margin: 0;
    max-width: 50%;
    width: 100%;
    font-weight: 700;
  }

  .value {
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
}

.paymentInfo {
  padding-top: $common-sm-padding;

  .paymentInfoSection {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.button {
  margin: 0 auto;
}



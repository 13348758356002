@import "src/variables";
@import "src/themify";

.labelWrapper {
  width: 170px;

  .label {
    @include themify {
      color: $primary-theme-color;
    }

    line-height: 24px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
  }
}

.deleteButton {
  padding: 0;
}

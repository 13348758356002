@import "src/variables";
@import "src/mixins";
@import "src/themify";

$type-icon-size: 28px;

.container {
  padding-top: $common-md-padding;
  padding-bottom: $common-md-padding;
}

.banner {
  background-size: cover;
  background-position: center;
  min-height: 500px;
  color: white;

  @media (max-width: $mobile-max-screen-width) {
    height: 100%;
  }
}


.container .name {
  color: white;
  font-size: 43px;
  margin: 0;
}

.topInfo {
  font-size: 20px;
  line-height: $type-icon-size;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .typeLabel {
    @include themify {
      color: $primary-theme-color;
    }
    display: flex;
    align-items: center;
  }

  .typeIcon {
    @include setIconSize($type-icon-size);

    margin-right: $common-xs-padding;
  }
}


.box {
  max-lines: 2;
  min-height: 95px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  color: black;
  padding: $common-xs-padding;


  .eventInfoRow {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 40px;
      height: 40px;
      margin-bottom: $common-xs-padding;
    }
  }
}

.additionInfoBox {
  margin-top: $common-lg-padding;
  color: white;
  background: $blue;

  .title {
    display: flex;
    align-items: center;
    font-size: 22px;

    svg {
      margin-right: $common-xs-padding;
    }

    .spinner {
      height: auto !important;
    }
  }

  .value {
    margin-top: $common-md-padding;
    display: block;
    font-size: 18px;
  }
}

.speakersBox {
  background: $yellow;
}

.description {
  ul {
    padding: revert;
    margin: revert;

    li {
      list-style: initial;
    }
  }
}

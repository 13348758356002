@import "src/variables";
@import "src/mixins";
@import "src/themify";

$background-image: '/assets/images/default/member-search-background.jpg';

.container {
  display: flex;
  flex-direction: column;

  .subTitleBox {
    margin-top: $common-md-padding;
    padding: $common-sm-padding;
    padding-left: 0;
    font-weight: bold;

    @include themify {
      color: $primary-theme-color;
    }
  }

  .bannerTitle {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: 0;
      font-size: 40px;
    }
  }

  .banner {
    background-image: url($background-image);
    background-size: cover;
    background-position: center;
    color: white;

    .bannerContent {
      min-height: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      .searchContainer, .locationContainer {
        border-radius: 20px 20px 0 0;
        align-items: end;
        padding: $common-md-padding $common-lg-padding $common-lg-padding;

        @include themify {
          background-color: rgba($primary-theme-color, 0.85);
        }

        .title {
          display: inline-block;
          margin: $common-xs-padding;
        }

        .input {
          background-color: white;
          border-radius: 20px;
        }

        .filterButton {
          display: flex;
          justify-content: space-between;
          width: 120px;
        }
      }

      .locationContainer {
        padding-top: 0;
      }
    }
  }

  .paginationWrapper {
    display: flex;
    justify-content: center;
  }

  .darkSection {
    background-color: $lightest-grey;

    .noResultText {
      text-align: center;
    }
  }

  .sectionContent {
    padding-top: $common-lg-padding;
    padding-bottom: $common-xl-padding;
  }
}

@import "src/variables";

$copy-button-border-radius: calc(#{$common-xs-padding} / 2);

.copyButton {
  border-radius: $copy-button-border-radius;
  height: 100%;
}

.copyButtonWrapper {
  background: white;
  border-radius: $copy-button-border-radius;
  margin-left: calc(#{$copy-button-border-radius} * -1);
  z-index: 1;
}

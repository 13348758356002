@import "src/variables";

.container {
  padding-top: $common-sm-padding;
}

.cardHeader {
  font-size: 20px;

  div {
    text-transform: none;
  }
}

.value {
  font-size: 36px;
}

.label {
  font-size: 12px;
  margin-top: $common-sm-padding;
  margin-bottom: 0;
}


@import "src/variables";
@import "src/themify";

.item {
  display: flex;
  align-items: baseline;

  &:not(:last-child) {
    margin-bottom: $common-md-padding;
  }
}

.price {
  font-weight: 500;
  margin-left: auto;
}

.info {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  margin-right: $common-xs-padding;

  .date {
    font-style: italic;
    color: $grey;
  }

  .title {
    font-weight: 500;

    @include themify {
      color: $primary-theme-color;
    }
  }
}

@import "src/variables";
@import "src/mixins";

.title {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0;
  @include themify {
    color: $primary-theme-color;
  }
}

.avatar {
  position: relative;
  width: 150px;

  .avatarImage {
    @include setSize(150px);
  }
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

.card {
  @include themify {
    background-color: rgba($primary-theme-color, 0.6);
  }
  position: relative;
  cursor: pointer;
  flex-grow: 1;
  padding-bottom: 100%;
  @media (max-width: $mobile-max-screen-width) {
    max-width: 100%;
  }

  &:hover {
    box-shadow: 0 15px 30px rgba(black, 0.25);
  }

  .content {
    position: absolute;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .logoWrapper {
    background-size: cover;
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;

    .logo {
      width: 100%;
      height: 100%;
    }
  }
  .titleWrapper {
    height: 120px;
    width: 100%;
    position: absolute;
    font-size: 14px;
    @include themify {
      background-color: rgba($primary-theme-color, 0.9);
    }
    bottom: 0;
    padding: $common-sm-padding $common-sm-padding $common-sm-padding $common-md-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: white;
    }

    .arrowIcon {
      @include setIconSize(50px);
      color: white;
    }
  }
}

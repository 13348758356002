@import "src/variables";
@import "src/mixins";
@import "src/themify";

.wrapper {
  display: flex;
}

.sidebar {
  z-index: 10;
}

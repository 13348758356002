@import "src/variables";

.downloadButtonLabel {
  margin-right: $common-xs-padding;
}

.countLabel {
  font-size: 14px;
}

.selectRoleField {
  border-radius: 20px;
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

$course-page-header-height: 90px;

.header {
  width: 100%;
  height: $course-page-header-height;
  background: white;
  box-shadow: $common-card-box-shadow;
}

.content {
  height: 100%;
  padding-top: $common-md-padding;
  padding-bottom: $common-md-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoWrapper, .logo {
  height: 100%;

  @media (max-width: $mobile-max-screen-width) {
    height: 30px;
  }
}

.logoWrapper, .smacnaLogo {
  height: 80px;
}

.cartButton {
  margin-right: $common-xs-padding;
}

.actionButton {
  @include themify {
    color: $primary-text-color;
  }
}

.actionIcon {
  @include setIconSize(36px);
}

@import "src/variables";
@import "src/themify";

.value, .label {
  display: inline-block;
  width: 100%;
}

.value {
  font-weight: 500;
  font-size: 60px;
}

.label {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: $common-sm-padding;
}

.card {
  color: white;
  text-align: center;
  height: 100%;

  .cardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &.primary {
    @include themify {
      background-color: $primary-theme-color;
    }
  }

  &.dark-red {
    background-color: $dark-red;
  }

  &.dark-grey {
    background-color: $dark-grey;
  }
}

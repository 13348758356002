@import "src/variables";
@import "src/mixins";
@import "src/themify";

$sidebar-menu-item-active-color: white;
$sidebar-menu-padding-vertical: 6px;

.actionIcon {
  @include setIconSize(36px);
}

.menuItem {
  padding: 0;

  .content {
    padding: $sidebar-menu-padding-vertical $common-sm-padding;
  }

  &:hover, &:focus {
    .content {
      @include themify {
        background-color: lighten($sidebar-item-background-color, 20%);
      }
    }

    color: white;
  }

  &:hover, .active {
    .content {
      color: $sidebar-menu-item-active-color;
    }
  }

  .active {
    .content {
      @include themify {
        background-color: $sidebar-item-background-color;
      }
    }
  }

  .menuItemLink {
    color: inherit;
    width: 100%;
  }
}


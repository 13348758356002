@import "src/variables";

.container {
  padding: $common-lg-padding 0;
  position: relative;
}

.section {
  margin-top: $common-lg-padding;
}

@import "src/variables";
@import "src/mixins";

.cancelButton {
  margin-right: auto;
}

.questionsWrapper {
  @include setPageContentStyles(true);
}

.saveButton {
  margin-left: $common-md-padding;
}

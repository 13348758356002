@import "src/variables";
@import "src/mixins";
@import "src/themify";

.title {
  font-size: 22px;
  margin-bottom: $common-sm-padding;
}

.divider {
  width: 100%;
  margin-bottom: $common-lg-padding;

  @include themify {
    background-color: $primary-theme-color;
  }
}

.helperText {
  display: block;
  margin-left: auto;
  margin-top: $common-xs-padding;
  width: 135px;
  font-style: italic;
  color: $grey-disabled;
  font-size: 13px;
}

.subTitle {
  margin: 0;
}

@import "src/variables";

.statusButtonLabel {
  margin-right: $common-xs-padding;
  white-space: nowrap;
}

.select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selectLabel {
  font-weight: 500;
  font-size: 18px;
  margin-right: $common-md-padding;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
}

.notFoundSection {
  text-align: center;
}

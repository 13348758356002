@import "src/variables";
@import "src/mixins";

$sidebar-background-color: #F9FAFB;
$sidebar-menu-item-active-color: white;
$sidebar-menu-icon-size: 38px;
$sidebar-list-item-icon-size: 30px;
$sidebar-padding-horizontal: 25px;
$sidebar-padding-vertical: $common-xs-padding;
$sidebar-collapse-icon-size: 20px;
$sidebar-collapsed-width: $sidebar-padding-horizontal * 2 + $sidebar-menu-icon-size;
$sidebar-header-height: 70px;
$sidebar-list-item-padding: 90px;
$sidebar-list-padding: 37px;

.sidebar {
  background: $sidebar-background-color;
  display: flex;
  flex-direction: column;
  width: $sidebar-width;
  transition: $common-transition;
  z-index: 2;

  & + * {
    transition: $common-transition;
    width: calc(100% - #{$sidebar-width});

    footer {
      padding-left: $sidebar-width;
    }
  }

  .menuIcon {
    display: none;
  }

  &.collapsed {
    width: $sidebar-collapsed-width;

    & + * {
      width: calc(100% - #{$sidebar-collapsed-width});

      footer {
        padding-left: $sidebar-collapsed-width;
      }
    }
  }

  .sidebarHeader {
    height: $sidebar-header-height;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    transition: $common-transition;
    padding: $sidebar-padding-vertical $sidebar-padding-horizontal;
    background-color: $sidebar-background-color;

    @media (max-width: $mobile-max-screen-width) {
      padding-left: $common-md-padding;
      padding-right: $common-md-padding;

      &.nccerSidebarHeader {
        height: auto;

        .nccerContainer {
          display: flex;
          align-items: center;
          flex-direction: column-reverse;
          position: relative;
          margin-right: auto;

          .nccerTitle {
            transform: translateY(50%);
            white-space: nowrap;
            text-align: center;
            font-size: 11px;
            margin-left: $common-sm-padding;
            font-weight: 700;
            @include themify {
              color: $primary-text-color;
            }
          }

          .nccerLogo {
            @include setSize(75px);

            margin-right: auto;

            img {
              height: 100%;
            }
          }
        }
      }
      &.gamsdSidebarHeader {
      }

      .logo {
        @include setSize(55px);

        margin-right: auto;

        img {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: $mobile-max-screen-width) {
    &, &.collapsed {
      width: 100%;
      height: auto;
      position: static;
    }

    &.collapsed + *, & + * {
      width: 100%;

      footer {
        padding-left: 0;
      }
    }


    &.menuVisible {
      .sidebarHeader {
        position: sticky;
      }

      .menuViewWrapper {
        max-height: calc(100% - #{$sidebar-header-height});
      }
    }

    .sidebarHeader {
      width: 100%;
    }

    .menuViewWrapper {
      background: $sidebar-background-color;
      transition: max-height .25s ease-out;
      position: sticky;
      z-index: 1000;
      top: $sidebar-header-height;
      height: 100%;
      width: 100%;
      overflow: hidden;
      max-height: 0;
    }

    .menuIcon {
      display: inherit;
    }
  }
}

.menu {
  list-style: none;
  padding: $sidebar-padding-vertical 0;

  .link {
    @include themify {
      color: $primary-text-color;
    }

    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    padding: 0;

    .content {
      display: inline-flex;
      align-items: center;
      background-color: transparent;
      width: 100%;
      height: 100%;
      padding: $common-xs-padding $sidebar-padding-horizontal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;

      @media (max-width: $mobile-max-screen-width) {
        padding-left: $common-md-padding;
        padding-right: $common-md-padding;
      }

      svg {
        width: 22px;
        height: 22px;
      }
    }

    .icon {
      @include setIconSize($sidebar-menu-icon-size);
    }

    .label {
      width: 100%;
      white-space: nowrap;
      transition: opacity .15s ease-in-out .3s;
      margin-left: $common-sm-padding;
    }

    &:hover, &.active {
      color: $sidebar-menu-item-active-color;

      .icon {
        color: $sidebar-menu-item-active-color;
      }
    }

    &:hover {
      .content {
        @include themify {
          background-color: lighten($sidebar-item-background-color, 20%);
        }
      }
    }

    &.active {
      .content {
        @include themify {
          background-color: $sidebar-item-background-color;
        }
      }
    }
  }

  .listLink {
    .content {
      padding-left: $sidebar-list-item-padding;
      height: $sidebar-menu-icon-size + $sidebar-padding-vertical * 2;
    }

    .icon {
      display: none;
      @include setIconSize($sidebar-list-item-icon-size);
    }
  }

  .divider {
    @include themify {
      background-color: $primary-text-color;
    }

    margin: $common-md-padding 0;
  }

  &.collapsed {
    .label {
      opacity: 0;
      width: 0;
      visibility: hidden;
      color: $sidebar-menu-item-active-color;
      transition: none;
    }

    .link:hover {
      .content {
        width: auto;

        .label {
          z-index: 0;
          opacity: 1;
          visibility: visible;
          width: auto;
          margin-left: $common-sm-padding;
          padding-left: $sidebar-padding-horizontal;
        }
      }
    }

    .content {
      .label {
        margin-left: 0;
      }
    }

    .listLink {
      .content {
        padding: $common-xs-padding $sidebar-list-padding;
      }

      .icon {
        display: block;
      }
    }
  }
}

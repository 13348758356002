@import "src/variables";
@import "src/mixins";

$chapter-button-min-height: 64px;
$chapter-avatar-size: 40px;

.content {
  padding: $common-sm-padding $common-lg-padding $common-lg-padding;
}

.container {
  padding-bottom: 0;
}

.noData {
  width: 100%;
  padding: $common-lg-padding $common-sm-padding;
  line-height: 24px;
  text-align: center;
}

.selectTitle {
  margin-top: 0;
}

.button {
  height: 100%;
  min-height: $chapter-button-min-height;
  justify-content: flex-start;
}

.chapterTitle {
  @include setUppercaseTitle();
}

.avatar {
  @include setSize($chapter-avatar-size);

  .avatarIcon {
    @include setIconSize(calc(#{$chapter-avatar-size} / 2));
  }
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}



@import "src/variables";
@import "src/mixins";

$type-icon-size: 28px;
$home-icon-size: 24px;

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  padding-top: $common-md-padding;
  padding-bottom: $form-page-footer-padding + $common-md-padding;

  .paymentSection {
    margin: $common-lg-padding 0;
  }
}

.pageTitle {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 0;

  span:not(:last-child) {
    margin-right: $common-xs-padding;
  }
}

.notFoundSection {
  text-align: center;
}

.card {
  .cardContent {
    padding: $common-lg-padding;
  }
}

.thinCard {
  .cardContent {
    padding: $common-sm-padding $common-lg-padding;
  }
}

.card, .thinCard {
  .cardContent {
    display: flex;
    justify-content: space-between;

    .title {
      text-transform: uppercase;
      margin: 0;
    }

    .title, .price {
      font-size: 20px;
      line-height: 34px;
      font-weight: bold;
    }

    .discount {
      font-size: 20px;
      line-height: 34px;
      font-weight: bold;
      color: green;
    }
  }
}

.backButton {
  margin-right: auto;
}

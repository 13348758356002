@import "src/variables";
@import "src/mixins";
@import "src/themify";

.cardContent {
  padding: $common-md-padding $common-lg-padding;
}

.nameWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .name {
    @include setUppercaseTitle;

    margin-right: $common-md-padding;
  }

  .name, .status {
    margin-bottom: $common-md-padding;
  }
}

.infoWrapper {
  margin-bottom: $common-md-padding;

  .label {
    font-weight: 700;
    margin-right: $common-xs-padding;
  }
}

.chartWrapper {
  @include setSize(200px);

  flex-shrink: 0;
  position: relative;
  margin-right: $common-lg-padding;

  .progress {
    position: absolute;
  }

  .background {
    color: $grey-disabled;
  }

  .label {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    padding: $common-xl-padding;
    align-items: center;
    justify-content: center;
    text-align: center;

    .labelCount {
      font-size: 44px;
    }
  }
}

.legendWrapper {
  .label {
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: $common-md-padding;
    }
  }

  .labelCount {
    font-size: 24px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
}

.label {
  @include themify {
    color: $primary-theme-color;
  }

  display: flex;
  flex-direction: column;
}

.labelCount {
  font-weight: 700;
}

.removeButton {
  display: flex;
  justify-content: flex-end;
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

$selected-label-height: 24px;

.card {
  @include setCardStyles(true);

  border-color: $grey;
  height: 100%;
  cursor: pointer;

  &.selected {
    position: relative;
    box-shadow: 0 15px 30px rgba(black, 0.5);
    border-width: 2px;
    overflow: initial;


    @include themify {
      border-color: $primary-theme-color;
    }

    .selectedLabel {
      position: absolute;
      display: inline-block;
      color: white;
      top: calc(-#{$selected-label-height} / 2);
      left: 50%;
      transform: translateX(-50%);
      min-width: 120px;
      padding: 0 $common-md-padding;
      line-height: $selected-label-height;
      font-weight: 500;
      border: 0;
      border-radius: 6px;
      text-align: center;
      white-space: nowrap;


      @include themify {
        background-color: $primary-theme-color;
      }
    }
  }

  @media (max-width: $mobile-max-screen-width) {
    max-width: 100%;
  }
}

.content {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: $common-md-padding;

  .title {
    @include setUppercaseTitle;

    font-size: 18px;
  }

  .price {
    font-size: 24px;
    display: inline-flex;
    align-items: baseline;
  }

  .title, .price {
    margin-bottom: $common-sm-padding;
  }

  .divider {
    width: 160px;

    @include themify {
      background-color: $primary-theme-color;
    }
  }

  .button {
    margin-top: auto;
  }
}

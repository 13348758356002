@import 'src/variables';
@import 'src/mixins';
@import 'src/themify';

$modal-padding: 32px;

.paper {
  width: 100%;
}

.content {
  &, &:first-child {
    padding: $modal-padding;

    @media (max-width: $mobile-max-screen-width) {
      padding: calc(#{$modal-padding} / 2) $modal-padding;
    }
  }
}

.title {
  padding: $modal-padding;
  background-color: $lightest-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 24px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .closeButton {
    padding: 0;
    margin-left: auto;

    svg {
      @include setIconSize(32px);
    }
  }
}

.footer {
  padding: calc(#{$modal-padding} / 2) $modal-padding;
}

.footerRow {
  @media (max-width: $mobile-max-screen-width) {
    flex-direction: column;

    & > button {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: calc(#{$modal-padding} / 2)
      }
    }
  }
}

.form, .spinner {
  height: 100%;
  overflow-x: hidden;
}

@import "src/themify";

.description {
  font-style: italic;
  margin: 0;
}

.submitButton {
  margin-left: auto;
}

.label {
  width: 100%;

  &.roleLabel {
    margin: 0;
  }
}

@import "src/mixins";

$row-icon-size: 24px;

.row {
  display: inline-flex;
  align-items: flex-start;
  line-height: $row-icon-size;
  font-size: inherit;
  color: inherit;

  &:not(:last-child) {
    margin-bottom: $common-xs-padding;
    margin-right: $common-xs-padding;
  }

  .icon {
    @include setIconSize($row-icon-size);

    margin-right: $common-xs-padding;
    @include themify {
      color: $primary-theme-color;
    }
  }
}

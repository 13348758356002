@import "src/variables";
@import "src/mixins";

.title {
  @include setUppercaseTitle;

  font-size: 21px;
}

.divider {
  @include themify {
    background-color: $primary-theme-color;
  }

  margin: $common-xs-padding 0;
  width: 100%;
}

@import "src/variables";

.paymentSection {
  padding-bottom: $common-lg-padding;
}

.cardSummary {
  margin-top: $common-lg-padding;

  .summary {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 22px;
    }
  }

}

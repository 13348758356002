@import "src/variables";

$review-header-height: 90px;

.cancelButton {
  margin-right: auto;
}

.content {
  width: 100%;
  display: flex;
  overflow: hidden;
  padding: $review-header-height 0 80px;

  .formWrapper {
    flex: 1 1 50%;
    padding: 20px;
    overflow-y: auto;

    &:first-child {
      border-right: 1px solid $mui-disabled-bg-color;
    }
  }
}

@import "src/variables";
@import "src/mixins";

.date {
  color: $grey;
  font-size: 14px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  margin: 14px 0;
}

.descriptionText {
  a {
    @include setLinkStyles;
  }
}

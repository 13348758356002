@import "src/variables";
@import "src/mixins";

.hints {
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
  margin-top: -$common-sm-padding;

  ul {
    display: flex;
    flex-direction: column;

    li {
      display: inline-block;
      list-style: none;

      &::before {
        content: '-';
        margin: 0 8px;
      }

      .hintIcon {
        vertical-align: middle;
      }
    }
  }
}

.hintIcon {
  @include setIconSize(18px);

  &.checked {
    color: $green;
  }

  &.unchecked {
    color: $red;
  }
}

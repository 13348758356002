@import "src/variables";

.input {
  padding-right: calc(#{$common-xs-padding} / 2)
}

.adornment {
  margin-left: 0;
}

.keyboardButton {
  padding: calc(#{$common-xs-padding} / 2)
}

.divider {
  text-align: center;
  display: inline-block;
  width: 100%;
  line-height: 42px;
}

@import "src/variables";

.row {
  display: flex;
  align-items: baseline;
  line-height: 18px;
  margin-bottom: $common-lg-padding;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    white-space: nowrap;
    min-width: 80px;
    margin-right: $common-md-padding;
    font-weight: 700;
  }

  @media (max-width: $mobile-max-screen-width) {
    flex-direction: column;
    margin-bottom: $common-sm-padding;

    .label, .value {
      margin-bottom: $common-sm-padding;
    }

    &:last-child > *:last-child {
      margin-bottom: 0;
    }

    .label {
      margin-right: 0;
    }
  }
}

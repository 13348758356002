@import "src/variables";
@import "src/mixins";
@import "src/themify";

.card {
  height: 100%;

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-weight: bold;
      font-size: 22px;
      @include themify {
        color: $primary-theme-color;
      }

      .name {
        margin: 0 $common-xs-padding;
      }
    }

    .location {
      display: flex;
      align-items: center;

      span {
        font-weight: bold;
        font-size: 18px;
        @include themify {
          color: $primary-theme-color;
        }
      }

      svg {
        @include themify {
          color: $primary-theme-color;
        }
        @include setIconSize(30px);
      }

    }

    .divider {
      width: 100%;
      margin-top: $common-xs-padding;
    }

    .info {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: flex-start;

      .row {
        display: inline-flex;
        align-items: center;
        margin-bottom: $common-xs-padding;
        font-size: 18px;

        .icon {
          @include setIconSize(30px);

          color: $grey;
          margin-right: $common-xs-padding;
        }
      }
    }
  }

  .segmentLabel {
    margin-bottom: $common-xs-padding;
    margin-right: $common-xs-padding;
  }
}

.spinner {
  height: 100%;
}

@import "src/variables";
@import "src/mixins";
@import "src/themify";

$nccer-logo-size: 130px;
$default-logo-size: 100px;
$teams-elite-logo-size: 140px;
$header-height: 390px;
$header-collapsed-height: $nccer-logo-size + 2 * $common-sm-padding;
$nccer-title-size: 200px;
$sna-header-color: radial-gradient(100% 100.00% at 0% 100%, #901386 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, rgba(37, 4, 34, 0.9) 10%, #370632 60%, rgba(55, 6, 50, 0) 100%), linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 70%);

.header {
  @include themify {
    background: $header-background;
  }
  width: 100%;
  min-height: $header-height;
  height: auto;
  display: flex;
  flex-direction: column;

  & + * {
    min-height: calc(100vh - #{$header-height});
  }


  &.collapsedView, &.nccerHeader {
    min-height: $header-collapsed-height;

    & + * {
      min-height: calc(100vh - #{$header-collapsed-height});
    }

    .content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.collapsedView, &.gamsdHeader {
    .gamsdLogo {
      top: 10px;
    }
  }

  &.snaHeader {
    @include themify {
      background: $sna-header-color, $header-background;
    }
  }

  &.teamsEliteHeader {
    .logo {
      @include setSize($teams-elite-logo-size);
    }

    .userText {
      text-shadow: 0 0 3px #000000;
    }
  }

  &.nccerHeader {
    .content {
      flex-direction: row;
    }

    .userText {
      width: 100%;
    }
  }
}

.content {
  @include setPageContentStyles(false, $common-xl-padding);

  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.nccerContainer {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  position: relative;

  .nccerTitle {
    white-space: nowrap;
    transform: translateY(50%);
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    margin-left: $common-md-padding;
    @media (max-width: $mobile-max-screen-width) {
      display: none;
    }
  }

  .nccerLogo {
    @include setSize($nccer-logo-size);

    align-self: flex-start;
    display: block;

    img {
      height: 100%;
    }

    @media (max-width: $mobile-max-screen-width) {
      display: none;
    }
  }
}

.logo {
  @include setSize($default-logo-size);
  position: absolute;
  top: 15px;
  left: 15px;
  align-self: flex-start;
  display: block;

  img {
    height: 100%;
  }

  @media (max-width: $mobile-max-screen-width) {
    display: none;
  }
}

.userText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $common-xs-padding;

  & span:not(:last-child) {
    margin-bottom: calc(#{$common-xs-padding} / 2)
  }

  .userName {
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;

    @media (max-width: $mobile-max-screen-width) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .userPosition {
    font-weight: 700;
  }

  .userMembership {
    font-style: italic;
  }
}

.skeleton {
  background-color: white;
}

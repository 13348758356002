@import "src/themify";

.loginContent {
  font-size: 24px;
}

.link {
  @include themify {
    color: rgba($primary-theme-color, 0.7);
  }

  font-weight: 700;
  cursor: pointer;

  &, &:hover {
    text-decoration: none;
  }
}

.loginPrompt {
  @include themify {
    color: $primary-theme-color;
  }

  font-weight: 700;
}
